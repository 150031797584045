import React from 'react';
import { Button as ReactButton } from 'reactstrap';

const Button = ({ label, variant, type, handleClick }) => {
  return (
    <ReactButton color={variant} type={type ? type : 'button'}>
      {label}
    </ReactButton>
  );
};

export default Button;

import React, { useState, useEffect } from 'react';
import Button from '../components/Button';
import ContactForm from '../components/ContactForm';
import { sendFormData } from '../services/services';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const Contact = () => {
  const [showFormModal, setFormModal] = useState(false);
  const toggle = () => setFormModal(!showFormModal);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const sendEmail = (formData) => {
    const data = {
      name: formData.target[0].value,
      email: formData.target[1].value,
      phone: formData.target[2].value,
      location: formData.target[3].value,
      service: formData.target[4].value,
      startDate: formData.target[5].value,
      endDate: formData.target[6].value,
      message: formData.target[7].value,
    };

    sendFormData(data)
      .then((res) => {
        setFormModal(true);
      })
      .catch(() => {
        console.log('Message not sent');
      });
  };

  const handleModalClick = (e) => {
    e.preventDefault();
    setFormModal(false);
  };

  return (
    <div className="contact">
      <Modal isOpen={showFormModal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Message Sent!</ModalHeader>
        <ModalBody>Thank you! We will be in contact soon.</ModalBody>
        <ModalFooter>
          <button
            className="btn btn-primary"
            type="button"
            onClick={handleModalClick}
          >
            Close
          </button>
        </ModalFooter>
      </Modal>

      <h1 className="header">Booking Request</h1>
      <p style={{ marginBottom: '20px' }}>Feel free to reach out via the form below, or call or text me at (818) 272-6576.</p>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          sendEmail(e);
        }}
      >
        <ContactForm />
        <div className="contact_buttons d-flex flex-column flex-md-row justify-content-between px-0">
          <Button label="Reset" variant="link" type="reset" />
          <Button label="Send" variant="primary" type="submit" />
        </div>
      </form>
    </div>
  );
};

export default Contact;

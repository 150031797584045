import React from 'react';
import { Input } from 'reactstrap';

const Field = ({ type, valid }) => {
  return (
    <div className="field">
      <Input
        bsSize="sm"
        className="mb-3"
        placeholder=""
        type={type ? type : 'text'}
        valid={valid ? valid : false}
      />
    </div>
  );
};

export default Field;

import React, { useEffect } from 'react';
import about from '../images/about.png';
import about2 from '../images/about2.png';

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="about">
      <h1 className="header">About</h1>
      <img src={about} className="about_img" alt="The Pet Nonna" />
      <p>
        Hello! I'm The Pet Nonna, your pet's second best friend and guardian
        when you're away. "Nonna" is an affectionate Italian term for
        grandmother, and Nonnas are often known for their warmth, nurturing
        nature, and love for their families. With a lifetime of love and care
        for animals, I've embraced the role of being a surrogate nonna to your
        furry companions.
      </p>
      <p>
        My journey into pet sitting began decades ago when I realized my passion
        for nurturing and connecting with animals. From cuddly kittens to
        energetic puppies, and even more senior pets needing extra care, I've
        had the pleasure of caring for a wide array of wonderful animals over
        the years.
      </p>
      <p>
        My approach to pet sitting is rooted in patience, understanding, and
        genuine affection. Much like a doting grandmother, I take the time to
        listen to each pet's needs, quirks, and preferences. Whether it's a
        belly rub for your feline friend, a game of fetch for your energetic
        pup, or simply providing comfort to an anxious pet, I tailor my care to
        ensure they feel cherished and at ease.
      </p>
      <p>
        Safety and well-being are my top priorities. I maintain a keen eye on
        their health, diet, and routines, following your instructions
        meticulously to provide a seamless experience in your absence. Whether
        it's administering medications, sticking to feeding schedules, or
        providing gentle companionship, I'm dedicated to maintaining their
        happiness and health.
      </p>
      <p>
        Beyond the basic needs, I believe in creating a warm and loving
        environment. Your pets become a part of my extended family while you're
        away. I create cozy spaces, offer plenty of cuddles, and ensure they
        never feel lonely or anxious. Regular updates and photos will keep you
        connected and reassured that your furry family member is in good hands.
      </p>
      <img src={about2} className="about_img2" alt="The Pet Nonna" />
      <p>
        Whether your pet needs a day visit, an overnight stay, rest assured
        they'll receive the same level of care and attention they would if you
        were home.
      </p>
      <p>
        I understand the trust you place in me as your pet's caregiver, and I
        take that responsibility with the utmost seriousness and affection. When
        you return, you'll find your pet not just well-cared-for but also with a
        heart full of new memories and a wagging tail or a contented purr to
        greet you.
      </p>
      <p>
        So, when life takes you away, know that The Pet Nonna is here to shower
        your beloved pet with all the love, cuddles, and care they need—just
        like a doting grandmother would.
      </p>
    </div>
  );
};

export default About;

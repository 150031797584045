import React from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import './styles/styles.css';
import Main from './pages/Main';
import Services from './pages/Services';
import About from './pages/About';
import Contact from './pages/Contact';
import Menu from './components/Menu';

function App() {
  return (
    <Router>
      <Menu />
      <Routes>
        <Route path="/" element={<Main />}></Route>
        <Route path="/services" element={<Services />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
      </Routes>
    </Router>
  );
}

export default App;

import React, { useEffect } from 'react';
import services from '../images/services.png';

const Services = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="services">
      <h1 className="header">Services</h1>
      <p>
        Drop-in visits cater to essential needs like quick bathroom breaks,
        feeding, and some quality cuddle time. If your furry friend requires
        extra exercise, opting for a walk means they'll get fed (if necessary)
        and receive added attention as well. When house sitting, I arrive an
        hour after your departure and leave an hour before your return, and will
        not be away from the house for more than a few hours at time, if at all.
        I'm more than happy to accommodate any special rules or needs your pet
        might have. I also take care of light housekeeping to ensure your home
        is neat and tidy upon your arrival, handling tasks like mail collection
        and plant watering. Being retired affords me a highly flexible schedule,
        giving me ample time to shower your pets with affection!
      </p>
      <p>
        I recommend reaching out for bookings at least two weeks beforehand,
        although I can occasionally accommodate last-minute requests. If you're
        interested in arranging a meet and greet, please get in touch—I'd be
        delighted to meet both you and your furry companions.
      </p>
      <p>
        Holiday rates are applied Thursday through Monday on holiday weekends
        and December 22 - January 3rd.
      </p>
      <p>
        I provide services in Glendale, La Canada, Pasadena, South Pasadena,
        Eagle Rock and Highland Park.
      </p>
      <div className="services_wrap">
        <div className="services_table">
          <div className="services_row">
            <div className="service_name">30 minute visit</div>
            <div className="service_price">$25</div>
          </div>
          <div className="services_row services_row_extra_margin">
            <div className="service_name">
              30 minute visit - <em>Holiday Rate</em>
            </div>
            <div className="service_price">$30</div>
          </div>
          <div className="services_row">
            <div className="service_name">60 minute visit</div>
            <div className="service_price">$50</div>
          </div>
          <div className="services_row services_row_extra_margin">
            <div className="service_name">
              60 minute visit - <em>Holiday Rate</em>
            </div>
            <div className="service_price">$60</div>
          </div>
          <div className="services_row">
            <div className="service_name">30 minute walk</div>
            <div className="service_price">$30</div>
          </div>
          <div className="services_row services_row_extra_margin">
            <div className="service_name">
              30 minute walk - <em>Holiday Rate</em>
            </div>
            <div className="service_price">$35</div>
          </div>
          <div className="services_row">
            <div className="service_name">60 minute walk</div>
            <div className="service_price">$55</div>
          </div>
          <div className="services_row services_row_extra_margin">
            <div className="service_name">
              60 minute walk - <em>Holiday Rate</em>
            </div>
            <div className="service_price">$65</div>
          </div>
          <div className="services_row">
            <div className="service_name">
              House Sitting - Full Day/Overnight
            </div>
            <div className="service_price">$75</div>
          </div>
          <div className="services_row services_row_extra_margin">
            <div className="service_name">
              House Sitting - Full Day/Overnight <em>Holiday Rate</em>
            </div>
            <div className="service_price">$90</div>
          </div>
          <div className="services_row">
            <div className="service_name">
              House Sitting - Half Day (Return home before 2pm)
            </div>
            <div className="service_price">$40</div>
          </div>
          <div className="services_row services_row_extra_margin">
            <div className="service_name">
              House Sitting - Half Day <em>Holiday Rate</em>
            </div>
            <div className="service_price">$45</div>
          </div>
        </div>
        <div className="services_image">
          <img src={services} alt="The Pet Nanny Services" />
        </div>
      </div>
    </div>
  );
};

export default Services;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Menu = () => {
  const [isNavOpen, setNavOpen] = useState(false);
  const toggleNav = (e) => {
    e.preventDefault();
    setNavOpen(!isNavOpen);
  };

  return (
    <div className={`menu ${isNavOpen ? 'menu--open' : ''}`}>
      <div className="menu_bar">
        <div className="menu_links-wrap">
          <Link to="/" style={{ textDecoration: 'none' }}>
            <div className="menu_link">Home</div>
          </Link>
          <Link to="/about" style={{ textDecoration: 'none' }}>
            <div className="menu_link">About</div>
          </Link>
          <Link to="/services" style={{ textDecoration: 'none' }}>
            <div className="menu_link">Services</div>
          </Link>
          <Link to="/contact" style={{ textDecoration: 'none' }}>
            <div className="menu_link">Booking </div>
          </Link>
          <p style={{ marginTop: '24px'}}>(818) 272-6576</p>
        </div>
        <button className="menu_toggle" type="button" onClick={toggleNav}>
          <div
            className={`menu_close-icon ${
              isNavOpen ? 'menu_close-icon--open' : ''
            }`}
          >
            <span />
            <span />
            <span />
          </div>
        </button>
      </div>
    </div>
  );
};

export default Menu;

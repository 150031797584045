import React, { useEffect } from 'react';
import logo from '../images/logo.png';
import yelp from '../images/yelplogo.png';


const Main = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="main">
      <div className="logo">
        <img src={logo} className="logo_img" alt="The Pet Nanny Logo" />
      </div>
      <div className="locations">
        GLENDALE • PASADENA • SOUTH PASADENA • LA CANADA • EAGLE ROCK • HIGHLAND
        PARK
      </div>
      <div className="yelp">
        <a href="https://www.yelp.com/biz/the-pet-nonna-glendale" target="_blank" rel="noreferrer">
          <img src={yelp} className="yelp_img" alt="Yelp Logo" />
        </a>
      </div>
    </div>
  );
};

export default Main;

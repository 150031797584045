import React from 'react';
import Field from '../components/Field';
import { Input } from 'reactstrap';
import contact from '../images/contact.png';

const ContactForm = () => {
  return (
    <div className="contact_blank">
      <div className="d-flex flex-row justify-content-between">
        <div className="w-75">
          <div className="contact_block">
            <label htmlFor="name">Name</label>
            <Field id={'name'} />
          </div>
          <div className="contact_block">
            <label htmlFor="email">Email</label>
            <Field id={'email'} type="email" />
          </div>
          <div className="contact_block">
            <label htmlFor="phone">Phone Number</label>
            <Field id={'phone'} />
          </div>
        </div>
        <div className="p-4">
          <img src={contact} className="contact_img" alt="The Pet Nonna" />
        </div>
      </div>
      <div className="contact_block">
        <label htmlFor="location">Address</label>
        <Field id={'location'} />
      </div>
      <div className="contact_block">
        <label htmlFor="service">Service</label>
        <Input className="mb-3" type="select">
          <option>30 Minute Visit</option>
          <option>60 Minute Visit</option>
          <option>30 Minute Walk</option>
          <option>60 Minute Walk</option>
          <option>House Sitting</option>
        </Input>
      </div>
      <div className="contact_block">
        <label>Dates</label>
        <div className="d-flex flex-row justify-content-between">
          <div className="mt-2" style={{ width: '48% ' }}>
            <label htmlFor="startDate" style={{ fontWeight: '400' }}>
              Start Date
            </label>
            <Input
              id="startDate"
              name="startDate"
              placeholder="Start Date"
              type="date"
            />
          </div>
          <div className="mt-2" style={{ width: '48% ' }}>
            <label htmlFor="endDate" style={{ fontWeight: '400' }}>
              End Date
            </label>
            <Input
              id="endDate"
              name="endDate"
              placeholder="End Date"
              type="date"
            />
          </div>
        </div>
      </div>
      <div className="contact_block mt-2">
        <label htmlFor="message">Additional Notes</label>
        <Input className="mt-2" fid="message" name="message" type="textarea" />
      </div>
    </div>
  );
};

export default ContactForm;
